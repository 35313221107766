import React from "react";

function Privacy() {
  return (
    <div style={{ maxWidth: "800px", margin: "40px auto", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Privacy Policy</h1>
      <p>Last Updated: October 1, 2023</p>
      
      <h2>1. Information We Collect</h2>
      <p>We collect information you provide directly to us, such as when you create an account, submit queries, or communicate with us.</p>
      
      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our services, develop new features, and protect our users.</p>
      
      <h2>3. Information Sharing</h2>
      <p>We do not share your personal information with third parties except as described in this Privacy Policy or with your consent.</p>
      
      <h2>4. Data Security</h2>
      <p>We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access.</p>
      
      <h2>6. Changes to this Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      
      <h2>7. Data Retention</h2>
      <p>We will retain your information for as long as your account is active or as needed to provide you services.</p>
      
      <h2>8. Your Rights</h2>
      <p>Depending on your location, you may have rights regarding your personal information, such as the right to access, correct, or delete your data.</p>
      
      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at founders@naturalui.com.</p>
    </div>
  );
}

export default Privacy; 