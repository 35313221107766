import React from "react";

function Terms() {
  return (
    <div style={{ maxWidth: "800px", margin: "40px auto", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Terms of Service</h1>
      <p>Last Updated: October 1, 2023</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using the PrayerAI application ("Service"), you agree to be bound by these Terms of Service.</p>
      
      <h2>2. Description of Service</h2>
      <p>PrayerAI provides a platform for spiritual guidance and prayer assistance through artificial intelligence.</p>
      
      <h2>3. User Accounts</h2>
      <p>You may be required to create an account to use certain features of the Service. You are responsible for maintaining the confidentiality of your account information.</p>
      
      <h2>4. Acceptable Use</h2>
      <p>You agree not to use the Service for any unlawful purpose or in any way that could damage or impair the Service.</p>
      
      <h2>5. Intellectual Property</h2>
      <p>All content, features, and functionality of the Service are owned by PrayerAI and are protected by copyright, trademark, and other intellectual property laws.</p>
      
      <h2>6. Disclaimer of Warranties</h2>
      <p>The Service is provided "as is" without warranties of any kind, either express or implied.</p>
      
      <h2>7. Limitation of Liability</h2>
      <p>PrayerAI shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Service.</p>
      
      <h2>8. Changes to Terms</h2>
      <p>PrayerAI reserves the right to modify these Terms at any time. Your continued use of the Service after such modifications constitutes your acceptance of the revised Terms.</p>
      
      <h2>9. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where PrayerAI is established.</p>
    </div>
  );
}

export default Terms; 