import "../../App.css";
import "./Home.css";
import React from "react";

function Home() {
  return (
    <div className="minimal-home">

      
      <div className="center-content">
        <div className="logo-container">
          <h1 className="logo-text">🧬💭 NaturalUI</h1>
        </div>
        
        <p className="description">Apps to increase human fitness</p>
        
        <a href="mailto:founders@naturalui.com" className="email-link">Email us</a>
      </div>
    </div>
  );
}

export default Home;